 .oswald {
     font-family: "Oswald", sans-serif;
     font-optical-sizing: auto;
     font-weight: weight;
     font-style: normal;
 }

 .freeman-regular {
     font-family: "Freeman", sans-serif;
     font-weight: 400;
     font-style: normal;
 }



 .bottom {
     width: 100%;
     height: auto;
     background-color: #ddd;
     color: rgb(6, 6, 6) !important;
     margin: 0;
     display: flex;
     flex-direction: row;
     font-family: Gill Sans, sans-serif;
     padding: 15px 0;
     box-sizing: border-box;
     position: relative
 }

 .copyright {
     width: 100%;
     margin: 10px 0 15px;
     text-align: center;
     justify-content: center;
     font-family: "Freeman", sans-serif;

 }

 .social-box {
     width: fit-content;
     justify-content: space-around;
     padding: 5px 2px 0;

 }

 .title-wrapper {
     width: 100%;
     margin: 20px 0 0 0;
     height: auto;
     display: table-cell;
     color: #0c0c0c;
     padding: 20px 0 0 20px;
     /* background-color: rgb(204, 228, 228); */
     float: left;
     line-height: 1.5;
 }

 .title {
     width: 100%;
     display: flex;
     text-wrap: wrap;

 }

 .title>a,
 p {
     width: 100%;
     /* display: flex; */
     color: rgb(20, 19, 19);
     text-wrap: wrap;
     margin: 0;


 }

 .title,
 .list-item,
 a {
     list-style-type: none;
     text-decoration: none;
     color: #090909;
 }

 .bottom,
 h3 {
     font-family: "Muli", sans-serif;
     color: #090909;
 }


 .svg-003 {
     display: flex;
     /* flex: 1; */
     width: 50px;
     height: 50px;
     cursor: pointer;
     /* gap: 15px; */
 }

 .svg-003:hover {
     content: '';
     color: rgb(8, 166, 114);
 }



 @media only screen and (min-width: 1025px) {

     a[href^=tel],
     a[href^=skype],
     a[href^=sms] {
         cursor: default;
         pointer-events: none;
     }

     .title-wrapper {
         width: 100%;
         margin: 0 0 0 0;
         height: auto;
         display: table-cell;
         color: #0c0c0c;
         padding: 20px 0 0 20px;
         /* background-color: rgb(204, 228, 228); */
         float: left;
         line-height: 1.5;
     }

     .svg-003 {
         display: block;
         /* flex: 1; */
         width: 50px;
         height: 50px;
         cursor: pointer;
         /* gap: 15px; */
     }

     .svg-003:hover {
         content: '';
         color: rgb(8, 166, 114);
     }


 }

 @media only screen and (max-width: 1023px) {

     a[href^=tel],
     a[href^=skype],
     a[href^=sms] {
         cursor: default;
         pointer-events: none;
     }

     .title {
         width: 100%;
         display: flex;
         text-wrap: wrap;

     }

     .title>a,
     p {
         width: 100%;
         /* display: flex; */
         color: rgb(20, 19, 19);
         text-wrap: wrap;
         margin: 0;
     }

     .title,
     .list-item {
         list-style-type: none;
         text-decoration: none;
     }

     .svg-003 {
         display: block;
         /* flex: 1; */
         width: 50px;
         height: 50px;
         cursor: pointer;
         /* gap: 15px; */
     }

     .svg-003:hover {
         content: '';
         color: rgb(8, 166, 114);
     }


 }

 @media only screen and (max-width: 767px) {


     .wrapper-title {
         width: 100%;
         display: block;
         letter-spacing: 0;
         margin: 25px 0 0 0;
         display: table-cell;
         padding: 20px;
         float: left;
     }

     .title {
         display: block;
     }

     .title,
     ul,
     li,
     a {
         text-decoration: none;
         color: #090909;
         font-size: 13px;
         letter-spacing: 0;
     }


     .bottom {
         font-size: 14px;

         color: rgb(6, 6, 6) !important;
         /* margin: 0 auto; */
         -webkit-line-clamp: inherit;
         box-sizing: border-box;
     }

     .svg-003 {
         display: block;
         /* flex: 1; */
         width: 50px;
         height: 50px;
         cursor: pointer;
         /* gap: 15px; */
     }

     .svg-003:hover {
         content: '';
         color: rgb(8, 166, 114);
     }
 }