.top-box {
    width: 100%;
    height: auto;
}

.top-box img {
    width: 100%;
    position: relative;
}

.top {
    width: 100%;
    top: 20px;
    margin-top: 1vh;

}