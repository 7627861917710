html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    max-height: 1000000px;
    margin: 0;
    padding: 0;
}

#wrapper {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.common-block {
    max-width: 100%;
    height: auto;
    margin-top: 40px;
    /* background-color: bisque; */
}

.about-p {
    margin: 25px 0 0px;

}

.service-box .title-holder {
    margin: 0 0 30px;
}

.box-holder {
    width: 100%;
    height: auto;
    padding: 5px;
    bottom: 0;
}

.hero-block {
    margin: 60px 0 0;
}

.hero-block p {
    margin: 0 0 40px;
}

.ctr {
    /* margin: 5% auto; */
    align-items: center;
    justify-content: center;
    display: grid;
    font-size: 18px/30px;
    font-weight: 700;
    font-family: 'Mulish';
}

.flex-001 {
    width: 100%;
    height: 80px;
    /* padding: 8px; */
    /* border: 1px dotted red; */
}

.right-box {
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
}

.svg-001 {
    width: 110px;
    height: 50px;
    /* background-color: #d10a0a; */

}

.svg-001:hover {
    color: rgb(7, 182, 86);
    transition-timing-function: cubic-bezier(.29, 1.01, 1, -0.68);
    cursor: pointer;
}

.block-content {
    width: 100%;
    height: auto;
    margin-left: 15px;
    padding: 5px;

}

/* 
  .grid-block {
    margin-top: 15px;
    padding: 10px;
  } */

.title-block {

    font-weight: bold;
    font-size: 23px;
}

.service-holder {
    width: 100%;
    height: 400px;
    /* background-color: #666; */
    margin-top: 25px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.service-holder p {
    text-align: justify;
    font-family: 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #3d3c3c;
}

.block-box {
    width: 100%;
    height: 350px;
    /* background-color: #666; */
    display: flex;
    box-sizing: border-box;
    margin: 20px 0 0 0;

}

.service-box {
    width: 30%;
    height: 350px;
    /* padding: 30px 20px; */
    /* margin: 0px auto; */
    text-align: justify;

}

.spl-ser {
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    -ms-flex-align: center;
    gap: 3px;

}

.svg-002 {
    width: 50%;
    height: 50px;
    margin-bottom: 17px;
}

.bold-font {
    font-family: 'Musli';
    font-weight: bolder;
    font-size: 17px;
}



.clients-box {

    height: auto;

    /* background-color: rgb(15, 16, 15); */
}

.client-slide {

    display: flex;

}

.img-holder {
    width: 100%;
    height: 270px;

}

.title-box {
    text-align: center;
    margin: 0 0 40px;
    border: transparent;

}

.title-p {
    font: 18px / 30px 'AvenirNext-Medium', sans-serif;
    font-size: 18px;
    margin-top: 10px;
    text-wrap: wrap;
    overflow-x: initial;
    letter-spacing: -0.45px;


}

.title-box h2 {
    align-items: center;
    font-weight: bolder;
    padding: 30px;
}


.button-lst {
    margin: 0 auto;
    align-items: center;
    width: 300px;
    height: 50px;
    display: block;
    background-color: black;
    color: aliceblue;
    border-radius: 5px;
    font-size: 30px;
    font-family: sans-serif;
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    .hero-block {
        margin: 60px 0 0;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 20px;
    }

    .pagetitle {
        padding: 30px 0;
        margin: 56px 0 0;
    }

    #header .navbar-collapse .navbar-nav {
        margin: 0;
    }

    .block {
        padding: 40px 0 10px;
    }

    .box-holder {
        width: 100%;
        /* height: 350px; */
    }

    .hero-block .carousel-caption {
        display: none;
    }

    .hero-block .carousel-control {
        width: 15%;
    }

    .title-holder {
        margin: 0 0 30px;
    }

    .hero-block .carousel-item img {
        width: 100%;
        height: 30vh;
    }

    .right-box {
        height: auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-row-gap: 5px;
    }

    .svg-001 {
        height: 50px;
    }

    .flex-001 {
        width: 100%;
        height: auto;
        /* background: rgb(215, 215, 215); */
        /* margin: -20px; */
        box-sizing: border-box;

    }

    .spl-ser {
        width: 100%;
        display: block;
    }

    .service-holder {
        width: 100%;
        height: auto;
        /* background-color: #666; */
        margin-top: 25px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }


    .block-box {
        width: 93%;
        height: fit-content;
        line-height: 25px;
        /* background-color: #666; */
        box-sizing: border-box;
        display: block;

    }

    .service-box {
        width: 93%;
        /* padding: 30px 20px; */
        /* margin: 0 auto; */
        object-fit: contain;
    }

    .svg-002 {
        width: 50%;
        height: 50px;
    }

    .service-box>.title-block {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 19px;
        font-weight: bold;
    }

    .service-box,
    .title-p {
        margin-top: 30px;
    }

    .client-slide {
        height: auto;
        display: flex;
    }

    .img-holder {
        /* width: 100px; */
        display: block;
        height: 120px;
    }

    /* just tried */
    .client-slide {
        display: block;
    }

    .title-p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        margin-top: 10px;
    }



}

@media only screen and (max-width: 1023px) {
    .hero-block .carousel-item img {
        width: 100%;
        height: 30vh;
    }

    body {
        font-size: 15px;
        line-height: 24px;

    }

    h1 {
        font-size: 34px;
        margin: 0 0 15px;
    }

    h2 {
        font-size: 26px;
        margin: 0 0 15px;
    }

    .btn.btn-primary {
        padding: 9px 35px;
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=search],
    textarea {
        padding: 5px 10px;
        font-size: 15px;
    }

    #header {
        padding: 10px 0;
    }

    #header .navbar-collapse .navbar-nav {
        float: none;
    }

    #header .navbar-collapse .nav-item {
        float: none;
        display: block;
        padding: 7px 0;
    }

    .hero-block .carousel-caption {
        left: 7%;
        right: 7%;
    }

    .hero-block .carousel-control {
        width: 5%;
    }

    .hero-block p {
        margin: 0 0 25px;
    }

    /* .works-block .portfoliolist>div .label {
        height: 50px;
    } */

    .title>a,
    p {
        margin: 10px;
    }


}

@media only screen and (min-width: 1025px) {

    a[href^=tel],
    a[href^=skype],
    a[href^=sms] {
        cursor: default;
        pointer-events: none;
    }

    /* #footer {
      background: #f64b4b;
      padding: 50px 0;
      color: #fff;
      text-align: center;
      bottom: 0 !important;
    } */
}

@media only screen and (max-width: 545) {}