html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
  margin: 0;
  padding: 0;
}

body {
  color: #333;
  background: #ffffff;
  /* font-family: Freeman, sans-serif; */
  font: 16px/1.6 "Muli", sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Freeman, sans-serif;
  font-weight: 400;
  margin: 0 0 20px;
  color: inherit;
}

h1 {
  font-size: 46px;
  color: rgb(70, 70, 80) !important;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 17px;
}

h6 {
  font-size: 15px;
}

p {
  margin: 0 0 15px;
}

a {

  text-decoration: none;
  transition: color 0.3s ease;
  /* font-weight: bold; */
}

a:hover,
a:focus {
  text-decoration: none;
  color: #f41a1a;
}

ul,
.teams-block .image .overlay,
.contact-block .contact-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
}

.content-block ul {
  margin: 0 0 15px;
}

.content-block ul li {
  position: relative;
  padding: 0 0 0 20px;
}

.content-block ul li:before {
  content: '\f101';
  font-family: 'fontawesome';
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.content-block ul li a {
  color: #333;
}

.content-block ul li a:hover {
  color: #f64b4b;
}

.btn.btn-primary {
  background: #f64b4b;
  border-color: #f64b4b;
  padding: 13px 35px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.3s linear, color 0.3s linear;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
}

.btn.btn-primary:focus,
.btn.btn-primary:hover {
  background: #fff;
  border-color: #f64b4b;
  color: #f64b4b;
}

.btn.btn-primary .fas {
  margin: 0 0 0 6px;
  font-size: 15px;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=search],
textarea {
  background: #fff;
  border: 1px solid #cccccc;
  margin: 0;
  padding: 5px 15px;
  width: 100%;
  height: 47px;
  color: gray;
  font-size: 16px;
  line-height: 20px;
}

input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: gray;
}

input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=search]::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: gray;
}

input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=search]:-moz-placeholder,
textarea:-moz-placeholder {
  color: gray;
}

input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: gray;
}

input[type=text].placeholder,
input[type=email].placeholder,
input[type=tel].placeholder,
input[type=search].placeholder,
textarea.placeholder {
  color: gray;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=search]:focus,
textarea:focus {
  outline: none;
}

.newsletter-block input[type=text],
.newsletter-block input[type=email],
.newsletter-block input[type=tel],
.newsletter-block input[type=search],
.newsletter-block textarea {
  border: 0;
}

textarea {
  height: 150px !important;
  min-height: 150px !important;
  resize: vertical;
  padding: 10px 15px !important;
}

.title-holder {
  text-align: center;
  margin: 0 0 25px;
}



.title-holder h2 {
  margin: 0 0 5px;
  text-transform: uppercase;
}



.title-holder .subtitle {
  color: #999999;
}

.title-holder .subtitle:before {
  content: '-';
  margin: 0 5px 0 0;
}

.title-holder .subtitle:after {
  content: '-';
  margin: 0 0 0 5px;
}

/* .socials {
  display: table;
  height: 100%;
  width: 100%;
}

.socials ul {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
} */

/* .socials li {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}

.socials a {
  width: 35px;
  height: 35px;
  background: #fff;
  color: #f64b4b;
  border-radius: 50%;
  display: block;
  text-align: center;
  padding: 2px;
  transition: background 0.3s linear, color 0.3s linear;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
}

.socials a:hover {
  background: #f64b4b;
  color: #fff;
} */

/* .socials .fab {
  line-height: 2;
} */

/* .go-top {
  width: 50px;
  height: 50px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #f64b4b;
  border-radius: 3px;
  color: #fff;
  border: 0;
} */

/* .go-top:before {
  width: 13px;
  height: 13px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content: '';
  border-style: solid;
  border-color: #fff;
  border-width: 1px 1px 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3px 0 0 -6px;
}

.go-top:hover {
  background: #f41a1a;
}

.pagination>li.active a {
  background: #f64b4b;
  border-color: #f64b4b;
} */

/* .pagination>li.active a:hover {
  background: #f64b4b;
  border-color: #f64b4b;
}

.pagination>li>a {
  color: #f64b4b;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  padding: 6px 15px 12px;
}

.pagination>li>a:hover {
  background: #f64b4b;
  color: #fff;
  border-color: #f64b4b;
} */

.pagetitle {
  margin: 80px 0 0;
  background: #f64b4b;
  color: #fff;
  padding: 50px 0;
}

.pagetitle .title-holder {
  margin: 0;
}

.pagetitle .subtitle {
  color: #e6e6e6;
}

#header {
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
  /* transition: all 0.5s linear; */
}

#header .navbar {
  /* display: flex; */
  padding: 2px;
  justify-content: space-between;
  background: none !important;
}

#header .navbar-brand {
  /* color: #f64b4b; */
  text-transform: uppercase;
  font-weight: 600;
  height: auto;
  padding: 0;
  font-size: 22px;
  line-height: 1.6;
}

.logo-set {
  width: 32px;
  height: 32px;
}

#header .navbar-brand .logo-text {
  width: 40px;
  font-size: '26px';
  text-transform: capitalize;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif, Helvetica, sans-serif;
  padding: 5px;
  color: rgb(18, 24, 57);

}

#header .navbar-collapse {
  flex-grow: 0;
}

#header .navbar-collapse .navbar-nav {
  font-size: 14px;
  line-height: 20px;
}

#header .navbar-collapse a:last-child {
  padding-right: 0;
}



.block {
  padding: 70px 0;
  border-bottom: 1px solid #e6e6e6;

}

.hero-block {
  margin: 60px 0 0;
}

.hero-block .carousel-item:before {
  content: '';
  /* background: rgba(18, 16, 16, 0.7); */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}

.hero-block .carousel-item img {
  width: 100%;
  height: 80vh;

}

.hero-block .carousel-caption {
  left: 25%;
  right: 25%;
  transform: translateY(50%);
  -webkit-transform: translateY(50%);
  bottom: 50%;
}

.hero-block p {
  margin: 0 0 30px;
}




@media only screen and (min-width: 1025px) {

  a[href^=tel],
  a[href^=skype],
  a[href^=sms] {
    cursor: default;
    pointer-events: none;
  }

  /* #footer {
    background: #f64b4b;
    padding: 50px 0;
    color: #fff;
    text-align: center;
    bottom: 0 !important;
  } */
}

@media only screen and (max-width: 1023px) {
  .title a p {
    width: 100%;
    display: inline-flex;
    margin: 8px;
  }

  .hero-block .carousel-item img {
    width: 100%;
    height: 30vh;
  }

  body {
    font-size: 15px;
    line-height: 24px;

  }

  h1 {
    font-size: 34px;
    margin: 0 0 15px;
  }

  h2 {
    font-size: 26px;
    margin: 0 0 15px;
  }

  .btn.btn-primary {
    padding: 9px 35px;
  }

  input[type=text],
  input[type=email],
  input[type=tel],
  input[type=search],
  textarea {
    padding: 5px 10px;
    font-size: 15px;
  }

  #header {
    padding: 10px 0;
  }

  #header .navbar-collapse .navbar-nav {
    float: none;
  }

  #header .navbar-collapse .nav-item {
    float: none;
    display: block;
    padding: 7px 0;
  }

  .hero-block .carousel-caption {
    left: 7%;
    right: 7%;
  }

  .hero-block .carousel-control {
    width: 5%;
  }

  .hero-block p {
    margin: 0 0 25px;
  }

  .works-block .portfoliolist>div .label {
    height: 50px;
  }


}

@media only screen and (max-width: 767px) {
  .hero-block .carousel-item img {
    width: 100%;
    height: 30vh;
  }

  .flex-001 {
    width: 100%;
    height: auto;
    /* background: rgb(215, 215, 215); */
    /* margin: -20px; */
    box-sizing: border-box;

  }

  .right-box {
    height: auto;
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 5px;
  }

  .svg-001 {
    width: 50%;
    height: 80px;
    /* margin-bottom: 17px; */
  }

  .svg-002 {
    width: 50%;
    height: 50px;
    margin-bottom: 17px;
  }

  .block-box {
    width: 93%;
    height: fit-content;
    /* line-height: 25px; */
    /* background-color: #666; */
    box-sizing: border-box;
    display: block;

  }

  .common-block {
    width: 100%;
    height: auto;
    /* border: 1px red dotted; */
    /* margin-top: 2ch; */
    /* background-color: bisque; */
  }

  .title-holder h2 {
    margin: 18px 0 5px;
    text-transform: uppercase;
    padding: 10px;
  }

  .title-holder p {
    margin: 0 0 5px;
    text-transform: uppercase;
    padding: 10px;
  }

  .hero-block {
    margin: 60px 0 0;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 20px;
  }

  .title-holder {
    margin: 0 0 40px;
  }

  .title-holder p {
    margin: 0 0 40px;
  }

  /* .go-top {
    width: 40px;
    height: 40px;
  }

  .go-top:before {
    width: 11px;
    height: 11px;
  } */

  .pagetitle {
    padding: 30px 0;
    margin: 56px 0 0;
  }

  #header {
    padding: 10px 0;
  }

  #header .navbar-collapse .navbar-nav {
    margin: 0;
  }

  .block {
    padding: 40px 0 10px;
  }

  .hero-block .carousel-caption {
    display: none;
  }

  .hero-block .carousel-control {
    width: 15%;
  }

  .btn-outline {
    border: none;
    font-weight: bolder;
    cursor: pointer;
  }

  .client-block {
    width: 100%;
    margin: 40px 0 0;
    font-weight: bold;

  }

  .client-holder {
    margin: 0 0 40px;
  }

  /* #footer {
    background: #f64b4b;
    padding: 50px 0;
    color: #fff;
    text-align: center;
    bottom: 0 !important;
  } */
}

/* <div className="block-box" sm={12}> */